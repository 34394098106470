<template>
  <el-form
    label-position="right"
    class="auto-form-content"
    :model="form"
    :rules="rules"
    ref="ruleForm"
  >
    <el-form-item
      label="广告账户"
      prop="account"
      ><el-select
        filterable
        v-model="form.account"
        placeholder="请选择广告账户"
        @change="handelAccountChange"
        v-loading="isAccountLoading"
      >
        <el-option
          v-for="(item, index) in accountList"
          :key="index"
          :label="item.adAccountName"
          :value="item.adAccountId"
        ></el-option></el-select
    ></el-form-item>
    <el-form-item
      label="排期"
      prop="adStartTime"
      ><div class="block">
        <el-date-picker
          v-model="form.adStartTime"
          type="datetime"
          placeholder="请选择排期"
          :default-value="currentDateTime"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
        >
        </el-date-picker></div
    ></el-form-item>
    <el-form-item
      label="像素"
      prop="pixelId"
      ><el-select
        v-model="form.pixelId"
        placeholder="请选择像素"
        v-loading="isPixelLoading"
      >
        <el-option
          v-for="(item, index) in pixelList"
          :key="index"
          :label="item.name"
          :value="item.id"
        ></el-option> </el-select
    ></el-form-item>
    <!-- <el-form-item
      label="投放地区"
      prop="countryCodes"
      ><el-select
        v-model="form.countryCodes"
        placeholder="请选择投放地区"
        multiple
        clearable
        filterable
        @change="handelCountryChange"
      >
        <el-option
          v-for="(item, index) in countryList"
          :key="index"
          :label="item.nameDesc"
          :value="item.code"
        ></el-option> </el-select
    ></el-form-item> -->
    <!-- <el-select
        v-model="form.interests"
        placeholder="请选择投放地区后再选择兴趣词"
        multiple
        clearable
        class="interest_select"
        @visible-change="changeInterest"
      >
        <el-option
          v-for="(item, index) in interestList"
          :key="index"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <div class="dp-flex ">
        <el-select
          v-model="interestTagName"
          placeholder="常用"
          multiple
          clearable
          class="interestTag_select"
          @change="handleCommand"
        >
          <el-option
            v-for="(item, index) in interestTagList"
            :key="index"
            :label="item.label"
            :value="item.id"
            class="interestTag_option"
          >
            <span class="tag_name">{{ item.label }}</span>
            <span
              class="tag_delete"
              @click.stop="interestLabelDel(item.id)"
              ><i class="el-icon-delete"></i></span
          ></el-option> </el-select
        ><el-button
          type="text"
          @click="saveAlert"
          >存为常用</el-button
        >
      </div> -->
    <!-- <el-form-item label="兴趣词"></el-form-item> -->
    <select-tag
      :data="interestList"
      :countryCodes="form.countryCodes"
      :params="{ id: 'id', name: 'name', children: 'childList', prop: 'location' }"
      label="兴趣词"
      keys="location"
      place="请搜索或选择兴趣词"
      :listDefault="form.interests"
      @setList="setList"
      ref="selectTag"
    ></select-tag>

    <el-form-item label="自定义受众"
      ><el-cascader
        v-model="form.audience"
        :options="customAudienceList"
        clearable
        filterable
        :props="{ expandTrigger: 'hover', multiple: true, label: 'name', value: 'id', children: 'list' }"
        :show-all-levels="false"
      ></el-cascader>
      <el-checkbox
        class="ml-100"
        v-model="enableTargetingExpansion"
        >扩展受众</el-checkbox
      ></el-form-item
    >
    <!-- 上传文件 activeTab 1 代表snapchat -->
    <upload-zip
      ref="uploadZip"
      @uploadSuccess="uploadSuccess"
      :activeTab="1"
      @uploadLoading="uploadLoading"
    ></upload-zip>
    <!-- 保存设置 -->
    <el-dialog
      title="保存为快捷选项"
      :visible.sync="saveInterestShow"
      width="600px"
      append-to-body
    >
      <div>
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="80px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="名称："
            prop="name"
          >
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="saveRegionShow = false">取 消</el-button>
        <el-button
          type="primary"
          @click="saveBtn"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import {
  querySnapchatCountry,
  interestQuery,
  interestLabelSave,
  interestLabelQuery,
  queryAdAccountByPlatform,
  querySnapchatPixelByAccountId,
  interestLabelDel,
  customAudienceQuery,
} from '@/api/addGoods';
import uploadZip from './UploadZip.vue';
import selectTag from './selectTag.vue';
import { mapState } from 'vuex';

export default {
  components: {
    uploadZip,
    selectTag,
  },
  data() {
    return {
      form: {
        account: '',
        pixelId: '',
        interests: [],
        countryCodes: [],
        adStartTime: '',
        audience: [],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
        start: '00:00',
        step: '00:30',
        end: '23:30',
      },
      currentDateTime: new Date(),
      enableTargetingExpansion: true,
      accountList: [], //账户列表
      pixelList: [], //像素列表
      countryList: [], //地区列表
      interestList: [], //兴趣列表
      interestTagList: [], //常用
      interestTagName: [], //选择的标签组
      customAudienceList: [], //自定义受众列表
      saveInterestShow: false, //存为常用的弹窗
      isAccountLoading: false, //账户加载
      isPixelLoading: false, //像素加载
      ruleForm: {},
      rules: {
        account: [{ required: true, message: '请选择广告账户', trigger: 'change' }],
        pixelId: [{ required: true, message: '请选择像素', trigger: 'change' }],
        countryCodes: [{ required: false, message: '请选择投放地区', trigger: 'change' }],
        adStartTime: [{ required: true, message: '请选择排期', trigger: 'change' }],
      },
    };
  },
  computed: {
    ...mapState({
      adAccountName: (state) => state.user.userInfo.data.name,
      userId: (state) => state.user.userInfo.data.id,
    }),
  },
  created() {
    //查询地区 获取地区列表
    // this.querySnapchatCountry();
    // 查询账户
    this.queryAdAccountByPlatform();
    //查询兴趣词标签 获取常用列表
    this.$nextTick(()=>{
      this.$refs.selectTag.changeInterestLabel();
      this.interestQuery();
    })
  },
  methods: {
    setList(v) {
      this.form.interests = v;
    },
    // 点击确认 父组件调子组件的方法
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const flattenedArray = this.form.audience.flat();
          const filteredArray = flattenedArray.filter((item) => item !== null).filter(item => item !== undefined);
          let params = {
            adAccountId: this.form.account,
            adAccountName: this.getLabelByValue(this.form.account, this.accountList, 'adAccountId', 'adAccountName'),
            pixelId: this.form.pixelId,
            // countryCode: this.form.countryCodes,
            interests: {
              category_id: this.form.interests,
              operation: 'INCLUDE',
            },
            segment: {
              segment_id: filteredArray,
              operation: 'INCLUDE',
            },
            enable_targeting_expansion: this.enableTargetingExpansion,
            adStartTime: this.form.adStartTime,
          };
          this.$refs.uploadZip.uploadZip(params);
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    //将各自的value值改为label名称
    getLabelByValue(value, array, code, name) {
      for (let item of array) {
        if (item[code] == value) {
          return item[name];
        }
      }
    },
    uploadSuccess() {
      this.clear();
      this.$emit('uploadSuccess');
    },
    clear() {
      this.form = {
        account: '',
        pixelId: '',
        interest: [],
        countryCodes: [],
        adStartTime: '',
        audience: [],
      };
      this.currentDateTime = new Date();
      this.enableTargetingExpansion = true;
      this.accountList = []; //账户列表
      this.pixelList = []; //像素列表
      this.countryList = []; //地区列表
      this.interestList = []; //兴趣列表
      this.interestTagList = []; //常用
      this.interestTagName = []; //选择的标签组
      this.customAudienceList = []; //自定义受众列表
      this.saveInterestShow = false; //存为常用的弹窗
      this.isPixelLoading = false; //账户接口是否在请求，像素加载
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
      this.$refs.uploadZip.clear();
    },
    handelCountryChange() {
      //查询兴趣词标签 获取常用列表
      // this.$refs.selectTag.changeInterestLabel();
      // this.interestQuery();
      // if (this.form.countryCodes.length == 0) {
      //   this.interestList = [];
      // } else {
      //   //查询兴趣词
      //   this.interestQuery();
      // }
    },
    handelAccountChange() {
      //账户像素查询
      this.querySnapchatPixelByAccountId();
      // 查询自定义受众
      this.customAudienceQuery();
    },
    //点击存为常用
    saveAlert() {
      if (!this.form.interests.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要保存的兴趣词',
        });
        return false;
      }
      this.saveInterestShow = true;
    },
    //存为常用弹框确定
    saveBtn() {
      if (!this.ruleForm.name) {
        this.$message({
          type: 'warning',
          message: '请输入兴趣词组名称',
        });
      } else {
        let params = {
          // id: 0,
          userId: this.userId,
          // countryCodes: this.form.countryCodes,
          adAccountId: this.$store.getters.accountId,
          label: this.ruleForm.name,
          interestIds: this.form.interests,
        };
        this.$showLoading();
        interestLabelSave(params).then((res) => {
          this.$hideLoading();
          // if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '保存成功',
          });
          this.saveInterestShow = false;
          // this.interestLabelQuery();
          // }
        });
      }
    },
    // 选择常用
    handleCommand() {
      let selects = this.interestTagName.length
        ? this.interestTagList
            .filter((m) => this.interestTagName.includes(m.id))
            .flatMap((item) => item.labelRelations.map((relation) => relation.id))
        : [];
      this.form.interests = [...new Set(selects)];
    },
    // 查询自定义受众
    customAudienceQuery() {
      customAudienceQuery({ accountId: this.form.account }).then((res) => {
        console.log(res);
        if (res.data && res.data.length > 0) {
          this.customAudienceList = res.data.map((item) => {
            return {
              ...item,
              name: item.key,
            };
          });
        }
      });
    },
    //查询兴趣词标签
    interestLabelQuery() {
      // if (this.form.countryCodes.length == 0) {
      //   return;
      // }
      let params = {
        userId: this.userId,
        // countryCodes: this.form.countryCodes,
        adAccountId: this.$store.getters.accountId,
        label: this.interestTagName.toString(),
      };
      interestLabelQuery(params).then((res) => {
        console.log(res);
        if (res.data && res.data.length > 0) {
          this.interestTagList = res.data;
        }
      });
    },
    //删除兴趣词标签
    interestLabelDel(id) {
      interestLabelDel({ id }).then((res) => {
        if (res.code == 0) {
          this.$message.success('删除成功');
        }
        this.interestLabelQuery();
      });
    },
    // 查询账户
    queryAdAccountByPlatform() {
      this.isAccountLoading = true;
      queryAdAccountByPlatform({ platform: 'snapchat' }).then((res) => {
        this.isAccountLoading = false;
        console.log(res);
        if (res.data && res.data.length > 0) {
          this.accountList = res.data;
        }
      });
    },
    //账户像素查询
    querySnapchatPixelByAccountId() {
      this.isPixelLoading = true;
      querySnapchatPixelByAccountId({ accountId: this.form.account }).then((res) => {
        this.isPixelLoading = false;
        console.log(res);
        if (res.data && res.data.length > 0) {
          this.pixelList = res.data;
        }
      });
    },
    //查询地区
    querySnapchatCountry() {
      this.$showLoading();
      querySnapchatCountry().then((res) => {
        this.$hideLoading();
        console.log(res);
        if (res.data && res.data.length > 0) {
          this.countryList = res.data;
        }
      });
    },
    //查询兴趣词
    interestQuery() {
      let params = {
        countryCodes: this.form.countryCodes,
      };
      // this.interestList
      interestQuery().then((res) => {
        this.flag = true;
        this.$hideLoading();
        console.log(res);
        if (res.data && res.data.length > 0) {
          this.interestList = res.data;
        }
      });
    },
    //保存兴趣词标签
    interestLabelSave() {},
    uploadLoading(bool = false) {
      this.$emit('uploadLoading', bool);
    },
  },
};
</script>

<style lang="scss" scoped>
.auto-form-content {
  height: 650px;
  overflow: scroll;
  padding-right: 10px;

  .el-form-item ::v-deep {
    .el-form-item__label {
      width: 100px;
    }
    .el-select {
      width: calc(100% - 100px);
    }
    .el-cascader {
      width: calc(100% - 100px);
    }
  }
  .ml-100 {
    margin-left: 100px;
  }
  .el-form-item__content {
    .interest_select {
      width: 280px;
      margin-right: 10px;
    }
    .interestTag_select {
      width: 80px;
      margin-right: 10px;
    }
  }
  .select_tags {
    margin-left: 100px;
    margin-bottom: 22px;
  }
}
.el-select-dropdown {
  .el-select-dropdown__list {
    .interestTag_option {
      padding-right: 20px;
      .tag_name {
        float: left;
        margin-right: 15px;
      }
      .tag_delete {
        float: right;
        color: #409eff;
      }

      &::after {
        content: '';
      }
    }
  }
}
</style>
