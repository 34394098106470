<template>
  <div class="addFb">
    <basic-msg
      :initData="initData"
      ref="basicMsg"
      @setCountry="setCountry"
      :accountMsg="accountMsg"
      :defaultConfigAdBeneficiary="defaultConfigAdBeneficiary"
      :id="id"
    ></basic-msg>
    <ads-msg
      :initData="initData"
      ref="adsMsg"
      :countryCode="country"
      :adsetMsg="adsetMsg"
    ></ads-msg>
    <upload-zip
      @uploadSuccess="uploadSuccess"
      @uploadLoading="uploadLoading"
      ref="uploadZip"
      :zipOssUrl="id ? zipOssUrl : ''"
    ></upload-zip>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import uploadZip from './ossUpload.vue';
import basicMsg from './fbComponents/basicMsg.vue';
import adsMsg from './fbComponents/adsMsg.vue';
import moment from 'moment';
import { getOptionData, autoUploadAd, queryCatchLeast, queryConfigAdBeneficiary } from '@/api/autoUploadGoods/common';
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      initData: {},
      country: '',
      accountMsg: {},
      adsetMsg: [],
      defaultConfigAdBeneficiary: {},
      zipOssUrl: '', //编辑的时候返回的oss 地址
    };
  },
  components: {
    uploadZip,
    basicMsg,
    adsMsg,
  },
  computed: {
    ...mapState('user', ['userInfo']),
  },
  methods: {
    uploadSuccess() {
      this.$emit('uploadSuccess');
    },
    uploadLoading() {
      this.$emit('uploadLoading');
    },
    getInitData() {
      getOptionData({ platform: 'facebook' }).then((res) => {
        Object.keys(res.data).forEach((v) => {
          this.$set(
            this.initData,
            v,
            res.data[v] ? (Array.isArray(res.data[v]) ? res.data[v] : res.data[v]['facebook']) : res.data[v],
          );
        });
      });
    },
    setCountry(v) {
      this.country = v;
    },
    validForm() {
      // 验证账户信息表单
      let PromiseList = [];
      // 验证每一个广告组表单规则
      this.$refs['adsMsg'].$refs['adsetMsg'].every((v) => {
        PromiseList.push(v.vaildForm());
      });
      //   验证每一个广告的表单规则
      this.$refs['adsMsg'].$refs['adsMsg'].every(async (v) => {
        PromiseList.push(v.vaildForm());
      });
      // 验证广告组内预算规则表单
      this.$refs['adsMsg'].$refs['bidMsg'].every((v) => {
        PromiseList = PromiseList.concat(v.vaildForm());
      });
      return PromiseList;
    },
    setBaseMsg() {
      let basicMsg = this.$refs['basicMsg'].ruleForm;
      console.log(this.$refs['adsMsg'].campaignList);
      let adsetMsg = this.$refs['adsMsg'].campaignList;
      let params = {
        assetInfo: basicMsg,
        list: adsetMsg.map((v) => {
          let obj = {};
          let { creative, adBudgetInfos, targetingInfo } = v;
          obj.targetingInfo = targetingInfo;
          obj.creative = creative;
          obj.adBudgetInfos = adBudgetInfos;
          return obj;
        }),
        platform: 'facebook',
        gimpUserId: this.userInfo.data.id,
        zipOssUrl: this.getFile(),
        recordId: this.id,
      };
      return params;
    },
    getFile() {
      if (!this.$refs['uploadZip'].fileOssUrl) {
        this.$message({
          type: 'warning',
          message: '请先上传压缩包',
        });
        return false;
      }
      return this.$refs['uploadZip'].fileOssUrl;
    },
    creatAds(flag) {
      this.$refs['basicMsg'].$refs['ruleForm'].validate((v) => {
        if (v) {
          let list = Promise.all(this.validForm());
          list.then((res) => {
            if (res.every((v) => v) && this.getFile()) {
              let params = this.setBaseMsg();
              this.$showLoading();
              autoUploadAd(params, params).then((res) => {
                this.$hideLoading();
                console.log(res);
                if (res.code == 0) {
                  this.$message({
                    type: 'success',
                    message: '上传成功',
                  });
                  if (!flag) {
                    this.uploadSuccess();
                  }
                }
              });
            } else {
              this.$refs['adsMsg'].setActiveCollapse();
            }
          });
        }
      });

      //   let flag = false;
      //   if (flag && this.getFile()) {

      //   } else {
      //     this.$refs['adsMsg'].setActiveCollapse();
      //   }
    },
    getCatchData() {
      this.$showLoading();
      queryCatchLeast({ recordId: this.id }).then((res) => {
        this.$hideLoading();
        if (res.code != 0) return;
        this.accountMsg = res.data.assetInfo;
        this.zipOssUrl = res.data.zipOssUrl;
        this.$refs.basicMsg.$refs.linkForm.setEditPromoteUrl();
        if (!this.id) {
          this.accountMsg.saleId = null;
          this.accountMsg.siteId = null;
          this.accountMsg.countryCode = null;
        }
        this.adsetMsg = res.data.list.map((v) => {
          if (!this.id) {
            // 创建的时候
            v.creative.content = '';
            v.creative.mediaType = 1;
            v.creative.title = '';
            v.creative.description = '';
            v.targetingInfo.facebook_include_interests = [];
            v.targetingInfo.countryCode = '';
            v.targetingInfo.dsaBeneficiary = '';
            v.targetingInfo.dsaPayor = '';
            v.targetingInfo.gender = 2;
          }
          v.adBudgetInfos = v.adBudgetInfos.map((v) => {
            if (new Date(v.startTime).getTime() < new Date().getTime()) {
              v.startTime = moment().format('YYYY-MM-DD HH:mm:ss');
            }
            return v;
          });
          console.log(v);
          return JSON.parse(JSON.stringify(v));
        });
      });
    },
    // 获取受益方赞助方
    getDefaultAdBeneficiary() {
      queryConfigAdBeneficiary().then((res) => {
        this.defaultConfigAdBeneficiary = res.data;
      });
    },
  },
  mounted() {
    this.getInitData();
    this.getDefaultAdBeneficiary();
  },
};
</script>
<style lang="scss" scoped>
.addFb {
  padding: 0 20px;
}
</style>
